import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SectionComponent } from '../components/section/section.component';
import { CardComponent } from '../components/card/card.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { DemoPageComponent } from './demo-page/demo-page.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ButtonComponent } from '../components/button/button.component';
import { ScenariosComponent } from '../components/scenarios/scenarios.component';

@NgModule({
    imports: [CommonModule, BrowserModule, FormsModule, RouterModule, AngularSvgIconModule.forRoot()],
    declarations: [
        SectionComponent,
        CardComponent,
        LandingPageComponent,
        DemoPageComponent,
        ButtonComponent,
        ScenariosComponent,
    ],
    exports: [],
    providers: [],
})
export class PagesModule {}
