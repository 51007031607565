import { Component, Input } from '@angular/core';
import { NavItem } from '../../shared/interfaces/navItem';

@Component({
    selector: 'navigation-bar',
    templateUrl: './navigation-bar.component.html',
    styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent {
    public isNavbarExpanded = false;
    @Input() public navLabel: string;
    @Input() public navLabelStyle: string;
    @Input() public navLogoUrl: string;
    @Input() public navItems: Array<NavItem>;

    toggleVisibility(): void {
        this.isNavbarExpanded = !this.isNavbarExpanded;
    }
}
