import { Component, Input } from '@angular/core';

// eslint-disable-next-line no-shadow
enum BACKGROUND {
    dark = 'dark',
    light = 'light',
}

// The SectionComponent class is a component that is used to create a section of the page. It has a
// unique id, a background type, and a list of classes that are applied to the section.
// It applies margins to the content other than that in pictureAboveContent, etc
@Component({
    selector: 'landing-section',
    templateUrl: './section.component.html',
    styleUrls: ['./section.component.scss'],
})
export class SectionComponent {
    //Every section is given a unique id which is be used by the scrollSpy directive
    private _id: number;
    static componentNumber = 1;
    public BACKGROUND_TYPE = BACKGROUND;
    @Input() backgroundType: BACKGROUND;
    @Input() sectionClassList: Array<string>;

    constructor() {
        this._id = SectionComponent.componentNumber++;
    }

    get Id() {
        return this._id;
    }
}
