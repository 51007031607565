import { Component, Input } from '@angular/core';

@Component({
    selector: 'road-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class CardComponent {
    @Input('title') title: string;
    @Input('description') description: string;
    @Input('imagePath') imagePath: string;
    @Input('backgroundType') backgroundType: 'light' | 'dark' = 'light';
}
