import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

//A directive that tracks which html native element is at the top of the window and emits its background color
@Directive({
    selector: '[BackgroundColorSpy]',
})
export class BackgroundColorSpyDirective {
    @Input() public spiedSelector: string;
    private _timeHasPassed = true;
    private _targets: Array<Element>;
    @Output() public onBackgroundChange = new EventEmitter<string>();

    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        // switch header background color back to white when on top of the page
        if (event.target.scrollTop === 0) {
            this.onBackgroundChange.emit('rgb(49, 39, 88)');
        }

        this.throttle(() => {
            if (!this._targets) {
                this._targets = Array.from(document.querySelectorAll(this.spiedSelector));
            }
            const targetsAtTop = this.isTargetAtTop(this._targets);
            const elAtTop = targetsAtTop.sort((a, b) => b.rect.y - a.rect.y)[0];

            if (elAtTop) {
                const color = window.getComputedStyle(elAtTop.element).getPropertyValue('background-color');
                this.onBackgroundChange.emit(color);
            }
        }, 100);
    }

    // a function to only call the wrapped functions every x milliseconds so the scroll event doesn't make our function run all the time
    throttle(func, timeFrame: number) {
        if (this._timeHasPassed) {
            this._timeHasPassed = false;
            setTimeout(() => {
                this._timeHasPassed = true;
            }, timeFrame);
            func();
        }
    }

    /**
     * It takes an array of elements and returns an array of elements that are at the top of the page
     * @param {Array<Element>} elements - Array<Element> - This is the array of elements that you want to check if they
     * are at the top of the page.
     * @returns {Array} An array of objects with the element and the rect of the element.
     */
    isTargetAtTop(elements: Array<Element>) {
        const isTop = elements.map((element) => {
            const rect = element.getBoundingClientRect();
            if (rect.y > 1) {
                return null;
            }
            return { element: element, rect: rect };
        });

        //Remove 0`s from the array and return it
        return isTop.filter(Boolean);
    }
}
