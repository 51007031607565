import { Component, ElementRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { rgbToHex } from './shared/functions/RgbToHex';

const MARGIN_TOP = 150;
// eslint-disable-next-line @typescript-eslint/ban-types
declare const gtag: Function; // <------------Important: the declartion for gtag is required!

// eslint-disable-next-line no-shadow
enum BACKGROUND {
    dark = 'dark',
    light = 'light',
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    @ViewChild('mainDiv', { read: ElementRef }) private _mainBody: ElementRef;
    private _ogMetaColor: string;
    currentTheme: BACKGROUND;

    // google analytics vars
    title = 'Bronia.AI';
    loadingDataImg = false;

    constructor(private router: Router) {
        this._ogMetaColor = document.querySelector('meta[name="theme-color"]')?.getAttribute('content');
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                gtag('config', 'G-5XM501RS80', { page_path: event.urlAfterRedirects });
            }
        });
    }

    onBackgroundChange(color: string) {
        this.changeMetaTag(color);
        this.changeCurrentTheme(color);
    }

    changeCurrentTheme(color: string) {
        //Removing white spaces because on different browsers it returns different strings
        const light = getComputedStyle(document.body).getPropertyValue('--color-light').replace(/ /g, '');
        // const dark = getComputedStyle(document.body).getPropertyValue('--color-dark').replace(/ /g, '');
        const reformatedColor = this.reformatColor(color);

        if (
            rgbToHex(reformatedColor[0], reformatedColor[1], reformatedColor[2]).toLowerCase() === light.toLowerCase()
        ) {
            this.currentTheme = BACKGROUND.light;
        } else {
            this.currentTheme = BACKGROUND.dark;
        }
    }

    // Most applicable for safari mobile users. It changes the color of the top bar
    changeMetaTag(color: string) {
        if (this._mainBody.nativeElement.scrollTop > MARGIN_TOP) {
            document.querySelector('meta[name="theme-color"]')?.setAttribute('content', color);
        } else {
            document.querySelector('meta[name="theme-color"]')?.setAttribute('content', this._ogMetaColor); //Changes back to white if at top of window
        }
    }

    reformatColor(color: string): Array<number> {
        const reformattedColor = color.slice(4, color.length - 1).replace(/ /g, '');
        const arr = new Array<number>();
        let num = '';
        for (const c of reformattedColor) {
            if (c === ',') {
                arr.push(Number(num));
                num = '';
            } else {
                num += c;
            }
        }
        arr.push(Number(num));
        return arr;
    }
}
