import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
    @Input() imagePath: string;
    @Input() text: string;
    @Output() onClick = new EventEmitter<MouseEvent>();

    onBtnClicked(event: MouseEvent) {
        this.onClick.emit(event);
    }
}
