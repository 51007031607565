import { Directive, Input, EventEmitter, Output, ElementRef, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Directive({
    selector: '[scrollSpy]',
})
export class ScrollSpyDirective implements OnInit {
    @Input() public spiedSelector: string;
    @Output() public sectionChange = new EventEmitter<string>();
    private currentSection = 'section1';

    constructor(private _el: ElementRef, private _location: Location, private router: Router) {}
    ngOnInit(): void {
        this.routeSubscribeParams();
    }

    /**
     * When the user scrolls, check if the current section has changed, and if so, call the
     * onSectionChange function.
     * @param {any} event - any - The scroll event
     */
    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        let currentSection: string;
        const children = this._el.nativeElement.querySelectorAll(this.spiedSelector); //Selects all elements down the doc tree
        const scrollTop = event.target.scrollTop;
        const parentOffset = event.target.offsetTop;
        for (let i = 0; i < children.length; i++) {
            const element = children[i];
            if (element.offsetTop - parentOffset <= scrollTop) {
                currentSection = element.id;
            }
        }
        if (currentSection !== this.currentSection) {
            this.currentSection = currentSection;
            this.onSectionChange(currentSection);
        }
    }

    /**
     * "When a section enters the user`s viewport, the URL changes to /landing-page/sectionId"
     * </code>
     * @param {string} sectionId - string - the id of the section that is in the viewpoint
     */
    onSectionChange(sectionId: string) {
        if (sectionId) {
            this._location.go(`/landing-page/${sectionId}`);
        }
    }

    /**
     * The scrollTo function takes a string as an argument and scrolls to the element with the id that
     * matches the string.
     * @param {string} section - The id of the section you want to scroll to.
     */
    scrollTo(section: string) {
        try {
            document.querySelector(`#${section}`).scrollIntoView();
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * When the route changes, if there are parameters, then scroll to the section with the id of the
     * parameter.
     * A bit crappy solution but route.params.subscribe doesn`t work on components outside of the router-outlet
     */
    routeSubscribeParams() {
        this.router.events
            .pipe(
                filter((e) => e instanceof ActivationEnd && Object.keys(e.snapshot.params).length > 0),
                map((e) => (e instanceof ActivationEnd ? e.snapshot.params : {}))
            )
            .subscribe((params) => {
                this.currentSection = params.sectionId;
                this.scrollTo(this.currentSection);
            });
    }
}
